// DATA LAYER CONFIGURATION
 const _dl = {
  "site": {
    'site': 'newsroom',
    "region": 'emea',
        "country": 'fr',
        "language": 'fr',
    "portal": "",
        "cms_version": "",
        "cds": "",        // in case of child directed page set this to 1

}, 'page': {
    "page_url": window.location.href,
        "page": window.location.pathname.replace(/\//g,":").replace(/^\:|\:$/,"") || "homepage",
        "page_title": document.title,
        "platform": "web",
        "slug": window.location.pathname,
  },
  "visitor": {
    //Example of other metadata used by other implementations
    "login_status": "logged out"
  },
  "privacy": {
        "cmp": "gdpr",
  },
  //Needed for if Data Layer is set up before Bootstrap.js is loaded
  "pageViewed": true
};
_dl.page.page_name = _dl.site.region + ":" + _dl.site.country + ":" + _dl.site.site + ":" + _dl.page.page;
// END OF DATA LAYER CONFIGURATION
// Subscribe and publish methods
_dl.subscribe = function(eventName, callback) {
  document.addEventListener("__dataLayer." + eventName, function(e) {
    if (e && e.detail)
      {callback.apply(__dataLayer, e.detail);}
}); };
_dl.publish = function(eventName) {
  var args = Array.prototype.splice.apply(arguments, [1, arguments.length]),
    event = new CustomEvent("__dataLayer." + eventName, {
      detail: args || []
    });
  document.dispatchEvent(event);
};
_dl.trackPage = function() {
        window.__dataLayer.pageViewed = true;
        window.__dataLayer.publish('pageView');
        document.dispatchEvent(new Event("dataLayerReady"));
};
_dl.OneTrustDialog = function(t) {
        var n = window.OneTrust;
        n && n.loaded && (t.preventDefault(), n.ToggleInfoDisplay());
};
window.__dataLayer = window.__dataLayer || {};
Object.assign(window.__dataLayer, _dl);
